import * as React from 'react'
import { Breadcrumb } from '../components/breadcrumb'

import Layout from '../components/layout'
import Seo from '../components/seo'

const Imprint = () => {
  const infoSection = (
    <div className="col-md-5">
      <h1>Impressum</h1>
    </div>
  )

  return (
    <Layout infoSection={infoSection}>
      <Seo title="Impressum" />
      <Breadcrumb crumbs={[{label: 'Impressum'}]} />

      <section className="content_info">
        <div className="paddings">
          <div className="container wow fadeInUp">
            <div className="row">
              <h3>Angaben gemäß § 5 TMG</h3>
              <p>
                Ulrich Diedrichsen
                <br />
                20144 Hamburg
              </p>
              <h4>Vertreten durch</h4>
              <p>
                Ulrich Diedrichsen <br />
              </p>
              <h3>Kontakt</h3>
              <p>
                E-Mail:{' '}
                <a href="mailto:business@moinsen.dev">business@moinsen.dev</a>
              </p>
              <h3>Redaktionell verantwortlich</h3>
              <p>
                Ulrich Diedrichsen
                <br />
                20144 Hamburg
              </p>
              <h3>EU-Streitschlichtung</h3>
              <p>
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:{' '}
                <a href="https://ec.europa.eu/consumers/odr/">
                  https://ec.europa.eu/consumers/odr/
                </a>
                . Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
              <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
              <p>
                Wir sind nicht bereit oder verpflichtet, an
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>
              <br />
              <br />
              <br />
              <span>Quelle: eRecht24</span>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Imprint
